import Link from 'next/link';
import { Heading, Link as ChakraLink, Text, VStack } from '@chakra-ui/react';

import { ErrorLayout } from '../components/error/error-layout';

export default function Error404() {
  return (
    <ErrorLayout title="Not found">
      <VStack as="main" spacing="4" placeSelf="center">
        <Text
          fontFamily="brand"
          opacity="0.3"
          color={'neutral.600'}
          fontSize="7xl"
        >
          404
        </Text>
        <Heading as="h1" fontSize={['2xl', '3xl']} textAlign="center" mb="4">
          Sorry, we could not find what you are looking for
        </Heading>
        <Link href="/" passHref legacyBehavior>
          <ChakraLink
            variant="primary"
            px="4"
            bg={'primary.800'}
            color={'neutral.white'}
            fontWeight="bold"
            borderRadius="button"
            py={4}
          >
            Enter the Arena
          </ChakraLink>
        </Link>
      </VStack>
    </ErrorLayout>
  );
}

Error404.allowGuest = true;
